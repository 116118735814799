<template>
<!-- 城区的 -->
    <div>
        <div class="goBack">
            <div @click="getBack('shaoxingone')" style="cursor: pointer;" v-if="iconShow">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span  @click="countiesopen">{{pitchon}}</span>
            <img src="../../assets/images/pull-down.png" alt="" style="cursor: pointer;" @click="countiesopen" v-if="iconShow">
            <ul class="counties" v-show="counties" v-if="iconShow">
                <li class="countiesli" v-for="(item,index) in select" :key="index" @click="countiesclick(item.id)">{{item.title}}</li>
            </ul>
        </div>
        <div>
            <jishanone v-if="jishanone"></jishanone>
        </div>
    </div>
</template>

<script>
import { show } from '../../assets/js/animate.js'
import bus from '/src/assets/js/bus.js'
import {getAjax, timestamp} from "../../assets/js/websocket";

export default {
    components: {
        jishanone: () => import('./jishanone.vue'),
    },
    data () {
        return {
            jishanone:true,
            jishantwo:false,
            shaoxingone:false,
            pitchon: "越城区",
            counties:false,
            // select:[ "新昌县","诸暨市","柯桥区","越城区","上虞区","嵊州市",] 
            select:window.downtown,
            iconShow:false  
        }
    },
    methods: {
        // 下拉箭头选择显示隐藏
        countiesopen(){
            if(this.counties === false){
                this.counties = true;
            }else{
                this.counties = false;  
            }
        },
        countiesclick(id){
            let _this = this;
            // console.log(id)
            var stree = event.target.innerText;
            this.pitchon = stree;
            this.counties = false;
            
            window.communityname = stree;
            setTimeout(() => {
                bus.$emit('now_area',window.communityname);
                bus.$emit('area_id',id)
            }, 200);
            

            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getShaoxingStreet?pid="+id,
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
                    lotteryjson.yuecheng.data.center = [parseFloat(res.data[0].longitude),parseFloat(res.data[0].latitude)]

					lotteryjson.yuecheng.data.points = [];
                    res.data.forEach((item,index)=>{
                      lotteryjson.yuecheng.data.points.push({
                            location: [
                                parseFloat(item.longitude),
                                parseFloat(item.latitude)
                            ],
                            name: item.name
                      })  
                    })
                    // console.log(lotteryjson.yuecheng)
                    _this.plug.upDataMap(lotteryjson.yuecheng)
                },
            })
        },
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
            this.plug.upDataMap(window.lotteryjson.home)
        },
        handle(){
            this.pitchon = window.communityname
        }
    },
    mounted () {
        this.handle();
        let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
        if(info.is_city == 1) this.iconShow = true;
        else this.iconShow = false;
    }
}
</script>

<style scoped>
.counties{
    display: inline-block;
    position: absolute;
    top:0.4rem;
    left:0.6rem;
    color:#F1F1F1;
    font-size: 0.19rem;
    text-align: center;
    background:#000000CC;
    padding:0.06rem 0rem;
    box-sizing: border-box;
    max-height:4.1rem;
    overflow-x: hidden;
    overflow-y: scroll;
}
.counties::-webkit-scrollbar {
        display: none;
    }
.countiesli{
    width:1.09rem;
    line-height: 0.40rem;
}
.countiesli:hover{
    color:#20D7FE;
    cursor: pointer;
}
.goBack>img{
    width:0.12rem;
    height:0.1rem;
    margin:0.12rem;
    cursor: pointer;
}
</style>
